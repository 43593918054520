<template>
  <div v-if="detail">
    <common-header :title="tit"></common-header>

    <div class="details-swiper">
      <!-- 商品图 start -->
      <div class="sp-banner-box pre">
         <span class="quehuo" v-if="specData.specList && specData.specList.length > 0 && specData.specList.inventory === 0">缺货</span>
        <swiper :options="bannerSwiper" class="home-swiper" v-if="detail != ''">
          <swiper-slide v-for="(item, index) in bannerData" :key="index">
            <div class="banner">
              <img :src="requestImgUrl(item.url)" alt="" />
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
      <!-- 商品图 end -->

      <!-- 秒杀 start -->
      <div class="seckill-box jb-r-l" v-if="seckillType">
        <div class="name">距离秒杀结束还有</div>
        <div class="count-down">
          <div class="st">{{ downObj.hour }}</div>
          :
          <div class="st">{{ downObj.minute }}</div>
          :
          <div class="st">{{ downObj.second }}</div>
        </div>
      </div>
      <!-- 秒杀 end -->
    </div>

    <div class="goods-price warp">
      <div class="box">
        <div class="commodity-info">
          <div class="name">{{ detail.name }}</div>
          <p class="ms">{{ detail.description }}</p>
          <div
            class="tags-box"
            v-if="
              (detail.specList &&
                (detail.specList[specIndex].seckill ||
                  detail.specList[specIndex].minLimit > 1)) ||
                  detail.specList[specIndex].userLimit > 0 ||
                detail.tags
            "
          >
            <div
              class="tag m-kill"
            >
              月销{{detail.virtualMonthlySales + detail.monthlySales}}
            </div>
            <div
              class="tag m-kill"
              v-if="detail.specList && detail.specList[specIndex].seckill"
            >
              秒杀
            </div>
            <template v-if="detail.tags">
              <div
                class="tag tags-item"
                v-for="(tagItem, tagIndex) in detail.tags.split(',')"
                :key="tagIndex"
              >
                {{ tagItem }}
              </div>
            </template>
            <div
              class="tag buy-from"
              v-if="detail.specList && detail.specList[specIndex].minLimit > 1"
            >
              {{ detail.specList[specIndex].minLimit }}{{detail.specList[specIndex].unitName!=''?detail.specList[specIndex].unitName:(detail.unitName)}}起购
            </div>
            <div
              class="tag m-kill"
              v-if="detail.specList[specIndex].userLimit > 0"
            >
              限购{{detail.specList[specIndex].userLimit}}
            </div>
          </div>
        </div>
        <!-- 认证 start -->
        <!-- <div class="auth-box">
          价格认证后可见
          <div class="go-auth">立即认证</div>
        </div> -->
        <!-- 认证 end -->

        <!-- 价格 start -->
        <div class="is-certified" v-if="(seckillType && specData.seckill.seckillPrice < 0) || specData.price < 0">{{isCertified()}}</div>
        <div v-else>
          <div class="price-box" v-if="seckillType">
            <div class="mark jb-r-l" v-if="seckillType">秒杀价</div>
            <div class="l">
              <span class="unit">￥</span
              ><span class="money">{{ specData.seckill.seckillPrice }}</span>
            </div>
            <div class="r">
              <span class="unit" v-if="detail.unitName != ''"
                >/{{ detail.unitName }}</span
              >
              <span class="money">￥{{ specData.price + specData.deposit }}</span>
            </div>
          </div>
          <div class="price-box" v-else>
            <div class="l">
              <span class="unit">￥</span>
              <span class="money">{{ specData.price + specData.deposit }}</span>
              <span class="unit">{{specData.unitName!=''?'/'+specData.unitName:(detail.unitName)}}</span>
              <span class="deposit" v-if="specData.deposit > 0">(含押金{{specData.deposit}}元)</span>
            </div>
            
            <!-- <div class="r">
              <span class="unit" v-if="detail.unitName != ''"
                >/{{ detail.unitName }}</span
              >
            </div> -->
          </div>
        </div>
        <!-- 价格 end -->

        <!-- 商品规格 start -->
        <div
          class="commodity-specificat"
          v-if="detail.specList"
        >
          <div
            class="item"
            v-for="(item, index) in detail.specList"
            :key="index"
            :class="specIndex == index ? 'active' : ''"
            @click="choiceSpec(index)"
          >
            <div class="spec-name">{{ item.name }}</div>
            <div class="spec-money" v-if="specData.price > 0">￥{{item.unitPrice}}{{item.minUnitName != '' ? ('/'+ item.minUnitName) : ''}}</div>
          </div>
        </div>
        <!-- 商品规格 end -->

        <!-- 分享按钮 start -->
        <div class="share-btn" @click="isShow = true">
          <i class="icon-goods-share-icon"></i>
          <p class="txt">分享</p>
        </div>
        <!-- 分享按钮 end -->
      </div>

      <!-- 描述信息 start -->
      <div class="describe-box" v-if="detail.safeServiceList">
        <div
          class="item"
          v-for="(item, index) in detail.safeServiceList"
          :key="index"
        >
          <div class="top">
            <img class="icon" :src="requestImgUrl(item.icon)" alt="" />
             <span>{{ item.name }}</span>
          </div>
          <span v-if="item.description != ''">{{item.description}}</span>
        </div>
      </div>
      <!-- 描述信息 end -->
    </div>

    <!-- 商品详情 start -->
    <div class="goods-details">
      <div class="title">商品详情</div>


      <!-- 参数详情 start -->
      <div
        class="parameter-details"
        v-if="detail.remark != '' && detail.remark"
      >
        <div class="box">
          <div
            class="item"
            v-for="(item, index) in detail.remark.split(/[(\r\n)\r\n]+/)"
            :key="index"
          >
            <div class="l">{{ item.replace(":", "：").split("：")[0] }}</div>
            <div class="r">{{ item.replace(":", "：").split("：")[1] }}</div>
          </div>
        </div>
      </div>
      <!-- 参数详情 end -->
      <div class="content warp" v-if="detail.detail != ''" v-html="detail.detail.replace('src','data-src')" v-lazy-container="{ selector: 'img'}"></div>
            <div class="product-service">
        <div class="product-service-title">
          <div class="diagonal"></div>
          <div class="text">服务承诺</div>
          <div class="diagonal"></div>
        </div>
        <div class="service-item" v-for="(item, index) in promiseData" :key="index">
          <img :src="requestImgUrl(item.icon)" alt="" class="cn-icon">
          <div >
            <h3>{{item.name}}</h3>
            <p>{{item.description}}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 商品详情 end -->

    <!-- 库存为0 的时候显示 start -->
    <div class="no-inventory" v-if="detail.specList[specIndex].inventory == 0">抱歉，商品已经售罄</div>
    <!-- 库存为0 的时候显示 end -->



    <wechat-share :isShow="isShow" @toParent="fromChild"></wechat-share>
    <!-- 浮动内容 start -->
    <div class="shopping-cart">
      <div class="fixed-content">
        <div class="l">
          <div class="item" @click="collect(detail.collect)">
            <div class="icon-box">
              <i
                :class="
                  detail.collect && detail.collect > 0
                    ? 'icon-shop-collection-active'
                    : 'icon-shop-collection'
                "
              ></i>
            </div>
            <p>收藏</p>
          </div>
          <router-link to="/shopping/car" class="item">
            <div class="icon-box">
              <i class="icon-shop-cart"></i>
              <mt-badge
                class="badge"
                color="#eb3030"
                size="small"
                v-if="goodsNum > 0"
                >{{ goodsNum }}</mt-badge
              >
            </div>
            <p>购物车</p>
          </router-link>
        </div>
        <div
          v-if="!buyOver && detail.specList[specIndex].inventory > 0"
          class="r"
          @click="SelectProducts()"
        >
          加入购物车
        </div>
        <div class="no-number-box" v-else>
          <div class="kong" @click="$router.push(`/shopping/home?firstCode=${detail.firstCode}&secondCode=${detail.secondCode}&thirdCode=${detail.thirdCode}`)">相似商品</div>
          <div class="jb-r-l" @click="dhReminder">到货提醒</div>
        </div>
      </div>
    </div>
    <!-- 浮动内容 end -->
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import {
  requestImgUrl,
  CountDown,
  isWeiXin,
  isUrlParam,
  DateFormat,
} from "@/utils/common";
import { isHttp, isCertified } from "@/utils/utils";
export default {
  data() {
    this.requestImgUrl = (url, privates) => requestImgUrl(url, privates);
    this.CountDown = (v) => CountDown(v);
    this.DateFormat = (v) => DateFormat(v);
    this.isUrlParam = (v) => isUrlParam(v);
    this.isCertified = () => isCertified();
    return {
      isShow: false,
      bannerSwiper: {
        loop: true,
         pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      },
      tit: "商品详情",
      id: "",
      // 详情数据
      detail: "",
      uid: localStorage.getItem("uid"),
      id: "",
      // 商品规格索引值
      specIndex: 0,
      // 规格商品详情
      specData: {},
      // 商品图
      bannerData: [],
      seckillType: false,
      // 倒计时
      downObj: {},
      // 抢购是否结束
      buyOver: false,
      promiseData: [],
    };
  },
  mounted() {
    let { query } = this.$route;
    let id = query.id;
    let specid = query.specid;
    this.id = id;
    this.GetCommodityDetail({
      id: id,
    }).then((res) => {
      this.detail = res.data;
      this.tit = res.data.name;
      this.specDetails({
        id: res.data.specList[0].id,
        specid: specid,
      });

      // 如果是在微信，请求分享接口
      if (isWeiXin()) {
        const url = this.isUrlParam({
          getUrlParam: "shareUserId",
        });
        // 分享url判断 如果当前用户登录，要传分享的用户shareUserId
        const shareUrl = this.$Cookies.get("user")
          ? url + "&shareUserId=" + JSON.parse(this.$Cookies.get("user")).userId
          : url;
        console.log(shareUrl);
        this.getWxConfig({
          title: res.data.name,
          link: encodeURI(shareUrl),
          desc: res.data.description,
          imgUrl: requestImgUrl(this.orgInfo.logo),
          success: function(res) {},
        });
      }
    });
    // 获取购物车数量
    this.getCommodityCount({
      uid: this.uid,
    }).then((res) => {
      this.goodsNumMuta(res.data.quantity);
    });


    // 服务 承诺列表
    this.getDetailsPromise().then((res)=> {
      this.promiseData = res.data;
    })
  },
  methods: {
    dhReminder() {
      this.AddCommodityNotice({
        commodityId: this.detail.id,
        commoditySpecId: this.detail.specList[this.specIndex].id,
      }).then((res)=> {
        this.$toast("已添加至到货提醒，到货后将在公众号中发消息提醒您。")
      })
    },
    fromChild(e) {
      this.isShow = e;
    },
    // 限时秒杀商品倒计时
    MsDownTime(endTime) {
      // 计算倒计时
      let nowDate = this.DateFormat(new Date());
      let endTimeRub = new Date(endTime.replace(/-/g, "/")).getTime();
      // 当前时间时间搓
      let currentTime = new Date(nowDate.replace(/-/g, "/")).getTime();
      let differTime = (endTimeRub - currentTime) / 1000;
      this.timer = setInterval(() => {
        differTime = differTime - 1;
        if (differTime <= 0) {
          clearInterval(this.timer);
        }
        this.downObj = this.CountDown(differTime);
      }, 1000);
      this.downObj = this.CountDown(differTime);
    },
    // 规格详情
    specDetails(obj) {
      let item = "";
      if (obj.id && obj.specid) {
        this.detail.specList.forEach((e, i) => {
          if (e.id == obj.specid) {
            item = e;
            this.specIndex = i;
          }
        });
      } else if (obj.id) {
        item = this.detail.specList.filter((item) => item.id == obj.id)[0];
      } else if (obj.item) {
        item = obj.item;
      }

      // 判断是否是秒杀商品
      if (item.seckill) {
        this.seckillType = true;
        this.MsDownTime(item.seckill.endTime);
      } else {
        this.seckillType = false;
      }
      // 判断商品图片
      if (item.imgs != "[]") {
        this.bannerData = JSON.parse(item.imgs);
      } else {
        this.bannerData = JSON.parse(this.detail.banner);
      }
      this.specData = item;
    },
    // 选择规格
    choiceSpec(index) {
      this.specIndex = index;
      this.specDetails({
        item: this.detail.specList[index],
      });
    },
    // 选择商品事件
    SelectProducts(commodityId, specId) {
      this.AddCommodityCart({
        commodityId: this.detail.id,
        specId: this.specData.id,
        uid: this.uid,
      }).then((res) => {
        if (res.status == 200) {
          this.$toast("加入购物车成功");
          // 获取购物车总数
          this.getCommodityCount({
            uid: this.uid,
          }).then((res) => {
            this.goodsNumMuta(res.data.quantity);
          });
        }
      });
    },
    // 收藏
    collect(e) {
      let { detail } = this;
      if (e && e > 0) {
        this.cancelCollect({
          targetId: this.id,
        }).then((res) => {
          detail.collect = 0;
          this.detail = detail;
        });
      } else {
        this.addCollect({
          targetId: this.id,
        }).then((res) => {
          detail.collect = 1;
          this.detail = detail;
        });
      }
    },
    ...mapMutations(["goodsNumMuta"]),
    ...mapActions("shopping", [
      "GetCommodityDetail",
      "getCommodityCount",
      "AddCommodityCart",
      "getDetailsPromise",
      "AddCommodityNotice",
    ]),
    ...mapActions(["addCollect", "cancelCollect", "getWxConfig"]),
  },
  computed: {
    ...mapState(["goodsNum", "orgInfo"]),
  },
};
</script>
<style lang="less" scoped>
.deposit{
  color: #ccc;
  font-size: .24rem;
  margin-left: .15rem;
}
.no-inventory{
  position: fixed;
  bottom: 1.13rem;
  left: 0;
  width: 100%;
  background: #ccc;
  text-align: center;
  color: #ff9662;
  font-size: .24rem;
  padding: .1rem;
  background: #ffe3cb;
}
.quehuo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 5;
      background-color: rgba(167, 167, 167, 0.7);
      color: #fff;
      width: 1.4rem;
      height: 1.4rem;
      font-size: 0.32rem;
      text-align: center;
      line-height: 1.4rem;
      display: block;
      border-radius: 1rem;
    }
.sp-banner-box{
  position: relative;
  .swiper-pagination{
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.product-service{
  background-color: #fff;
  padding: .3rem .2rem;
  border-radius: .1rem;
  margin-top: .2rem;
  margin-bottom: .2rem;
  overflow: hidden;
  .product-service-title{
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    .diagonal{
          flex: 1;
    height: .17rem;
    background: repeat-x linear-gradient(
    -60deg
    , transparent, transparent 45%, #ccc 45%, #ccc 55%, transparent 55%);
        background-size: .13rem .17rem;
    }
    .text{
      font-size: .36rem;
      font-weight: bold;
      color: #222;
      margin: 0 .2rem;
    }
  }
  .service-item{
    display: flex;
    font-size: .24rem;
    margin-top: .36rem;
    .cn-icon{
      margin-right: .2rem;
      height: .7rem;
      width: .7rem;
      border-radius: 50%;
    }
    h3{
      color: #333;
      font-size: .26rem;
      font-weight: bold;
      line-height: 1;
      margin-bottom: .14rem;
    }
    p{
      color: #999;
      line-height: 1;
      font-size: .24rem;
    }
  }
}



.parameter-details {
  background: #fff;
  border-radius: 0.1rem;
  padding: 0.2rem;
  margin-bottom: 0.2rem;
  .box {
    border: 1px solid #eee;
  }
  .item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    &:last-child {
      border-bottom: none;
    }
    .l {
      font-size: 0.26rem;
      color: #bfbfbf;
      width: 1.5rem;
      text-align: center;
      padding: 0 0.1rem;
      margin-right: 0.2rem;
      border-right: 1px solid #eee;
      padding: 0.1rem 0;
    }
    .r {
      font-size: 0.26rem;
      color: #262626;
    }
  }
}
.commodity-info {
  padding-right: 1rem;
}
.seckill-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.85rem;
  color: #fff;
  line-height: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
}
.count-down {
  display: flex;
  color: #fff;
  margin-left: 0.15rem;
  .st {
    min-width: 0.4rem;
    padding: 0 0.05rem;
    height: 0.4rem;
    background: #fff;
    font-size: 0.24rem;
    color: #ff7341;
    text-align: center;
    border-radius: 0.05rem;
    line-height: 0.4rem;
    margin: 0 0.02rem;
  }
}
.details-swiper {
  position: relative;
}
.commodity-specificat {
  display: flex;
  margin-top: 0.2rem;
  .item {
    padding: 0.1rem 0.2rem;
    background: #f7f7f7;
    text-align: center;
    margin-right: 0.2rem;
    border-radius: 0.05rem;
    &.active {
      background: rgba(255, 150, 98, 1);
      .spec-name,
      .spec-money {
        color: #fff;
      }
    }
    .spec-name {
      color: #000;
      font-size: 0.28rem;
    }
    .spec-money {
      font-size: 0.26rem;
      color: #808080;
      margin-top: 0.05rem;
    }
  }
}
.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7.5rem;
}
.shopping-cart {
  height: 1.13rem;
  .fixed-content {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1.13rem;
    background: #fff;
    display: flex;
    .l {
      display: flex;
      align-items: center;
      width: 3rem;
      justify-content: center;
      .item {
        margin: 0 0.25rem;
        text-align: center;
      }
      .icon-box {
        position: relative;
        height: 0.4rem;
        align-items: center;
        .badge {
          position: absolute;
          top: -0.05rem;
          right: 0;
          font-size: 0.24rem;
          transform: translateX(40%);
        }
      }
      p {
        font-size: 0.26rem;
        color: #a7a7a7;
        margin-top: 0.14rem;
      }
    }
    .no-number-box{
      font-size: 0.32rem;
      text-align: center;
      font-weight: 700;
      flex: 1;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 0 .2rem;
      &>div{
        border: 1px solid #fff;
        flex: 1;
        margin-right: .2rem;
        height: .7rem;
        line-height: .7rem;
        border-radius: .5rem;
        &:last-child{
          margin-right: 0;
        }
      }
      .kong{
        border: 1px solid #ff7141;
        color: #ff7141;
      }
    }
    .r {
      
      &.hui {
        background: #bdbdbd;
      }
      font-size: 0.32rem;
      line-height: 1.13rem;
      text-align: center;
      font-weight: 700;
      flex: 1;
      color: #fff;
      height: 100%;
      background: rgba(255, 150, 98, 1);
      background: -moz-linear-gradient(
        45deg,
        rgba(255, 150, 98, 1) 0%,
        rgba(255, 113, 65, 1) 91%,
        rgba(255, 113, 65, 1) 100%
      );
      background: -webkit-gradient(
        left bottom,
        right top,
        color-stop(0%, rgba(255, 150, 98, 1)),
        color-stop(91%, rgba(255, 113, 65, 1)),
        color-stop(100%, rgba(255, 113, 65, 1))
      );
      background: -webkit-linear-gradient(
        45deg,
        rgba(255, 150, 98, 1) 0%,
        rgba(255, 113, 65, 1) 91%,
        rgba(255, 113, 65, 1) 100%
      );
      background: -o-linear-gradient(
        45deg,
        rgba(255, 150, 98, 1) 0%,
        rgba(255, 113, 65, 1) 91%,
        rgba(255, 113, 65, 1) 100%
      );
      background: -ms-linear-gradient(
        45deg,
        rgba(255, 150, 98, 1) 0%,
        rgba(255, 113, 65, 1) 91%,
        rgba(255, 113, 65, 1) 100%
      );
      background: linear-gradient(
        45deg,
        rgba(255, 150, 98, 1) 0%,
        rgba(255, 113, 65, 1) 91%,
        rgba(255, 113, 65, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff9662', endColorstr='#ff7141', GradientType=1 );
    }
  }
}
.goods-details {
  padding: 0 0.2rem .5rem;
  .content {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    background: #fff;
    border-radius: 0.1rem;

  }
  img {
    max-width: 100%;
  }
  .title {
    font-size: 0.32rem;
    color: #bfbfbf;
    text-align: center;
    height: 1.05rem;
    line-height: 1.05rem;
    position: relative;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1.3rem;
      height: 1px;
      background: #bfbfbf;
      content: "";
      margin-left: 47px;
    }
    &::before {
      margin-right: 47px;
      position: absolute;
      top: 50%;
      right: 50%;
      width: 1.3rem;
      height: 1px;
      background: #bfbfbf;
      content: "";
    }
  }
}
.describe-box {
  border-radius: 0.1rem;
  background: #fff;
  margin-top: 0.2rem;
  padding-top: 0.25rem;
  padding-left: 0.3rem;
  padding-bottom: 0.25rem;
  .item {
    .top{
      display: flex;
      align-items: center;
      margin-bottom: .1rem;
    }
    font-size: 0.26rem;
    color: #727272;
    margin-bottom: 0.2rem;
    &:last-child {
      margin-bottom: 0;
    }
    .icon {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.17rem;
    }
  }
}
.price-box {
  display: flex;
  align-items: flex-end;
  margin-top: 0.3rem;
  .mark {
    color: #fff;
    font-size: 0.24rem;
    width: 1rem;
    height: 0.42rem;
    border-radius: 0.2rem;
    text-align: center;
    line-height: 0.42rem;
    margin-right: 0.2rem;
  }
  .l {
    color: #ff7241;
    line-height: 1;
    .unit {
      font-size: 0.24rem;
    }
    .money {
      font-size: 0.46rem;
    }
  }
  .r {
    font-size: 0.26rem;
    color: #bfbfbf;
    padding-bottom: 0.05rem;
    margin-left: 0.08rem;
    .money {
      margin-left: 0.08rem;
      text-decoration: line-through;
    }
  }
}
.goods-price {
  margin-top: 0.2rem;
  .box {
    position: relative;
    background: #fff;
    padding: 0.29rem 0.35rem 0.38rem;
    border-radius: 0.1rem;
    .share-btn {
      position: absolute;
      right: 0.35rem;
      top: 0.28rem;
      text-align: center;
      .txt {
        font-size: 0.28rem;
        color: #ff7241;
      }
    }
    .auth-box {
      margin-top: 0.37rem;
      .go-auth {
        background: #fff;
        color: #ff7241;
        font-size: 0.28rem;
        width: 1.88rem;
        height: 0.54rem;
        line-height: 0.54rem;
        text-align: center;
        border-radius: 0.27rem;
      }
      display: flex;
      justify-content: space-between;
      height: 0.85rem;
      align-items: center;
      padding: 0 0.25rem;
      font-size: 0.3rem;
      color: #ffffff;
      border-radius: 0.1rem;
      background: rgba(255, 113, 65, 1); /* Old Browsers */
      background: -moz-linear-gradient(
        left,
        rgba(255, 113, 65, 1) 0%,
        rgba(255, 185, 139, 1) 100%
      ); /* FF3.6+ */
      background: -webkit-gradient(
        left top,
        right top,
        color-stop(0%, rgba(255, 113, 65, 1)),
        color-stop(100%, rgba(255, 185, 139, 1))
      ); /* Chrome, Safari4+ */
      background: -webkit-linear-gradient(
        left,
        rgba(255, 113, 65, 1) 0%,
        rgba(255, 185, 139, 1) 100%
      ); /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(
        left,
        rgba(255, 113, 65, 1) 0%,
        rgba(255, 185, 139, 1) 100%
      ); /* Opera 11.10+ */
      background: -ms-linear-gradient(
        left,
        rgba(255, 113, 65, 1) 0%,
        rgba(255, 185, 139, 1) 100%
      ); /* IE 10+ */
      background: linear-gradient(
        to right,
        rgba(255, 113, 65, 1) 0%,
        rgba(255, 185, 139, 1) 100%
      ); /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff7141', endColorstr='#ffb98b', GradientType=1 ); /* IE6-9 */
    }
    .name {
      font-size: 0.32rem;
      font-weight: 700;
      color: #262626;
      margin-bottom: 0.1rem;
    }
    .ms {
      font-size: 0.26rem;
      color: #bfbfbf;
    }
  }
}
</style>
